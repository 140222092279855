<template lang="html">
    <div>
        <b-navbar type="light" class="nav-bg" fixed="top" style="position: fixed;height: 50px;">
            <router-link to="/dashboard" style="margin-left: 20px;font-size: 32px;">
                    <b-icon icon="arrow-left" font-scale="1"></b-icon>
            </router-link>
            <span>Remain List</span>
        </b-navbar>
        <div class="container-fluid" style="margin-top: 16%;margin-bottom: 20%;">
            <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
            <b-row  style="margin-top: 2%;">
                <b-col>
                    <b-card v-for="remain in remains" class="mt-2">
                        <b-row class="justify-content-md-center">
                            <b-col>
                                <h3>{{remain.name}}</h3>
                            </b-col>
                        </b-row>
                        <hr class="mt-2 mb-2">
                        <b-row class="justify-content-md-center">
                            <b-col cols="12">
                                <p v-for="item in remain.item">{{item.name}} : {{item.qty}}</p>
                            </b-col>  
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
        </div>    
    </div>
</template>

<script lang="js">
    import {
        mapState,
        mapActions
    } from 'vuex'
    import Loading from 'vue-loading-overlay'
    import 'vue-loading-overlay/dist/vue-loading.css';
    export default {
        name: 'dashboard',
        components: {
            Loading
        },
        data() {
            return {
                isLoading: false,
                fullPage: true,
                status: 'pending',
                remains: []
            }
        },
        computed: {
            ...mapState({})
        },
        methods: {
            ...mapActions({
                remainReturnItemAction: 'remainReturnItemAction',
            }),
            async remainInfo() {
                await this.remainReturnItemAction().then(res => {
                    if (res.status == "success") {
                        this.remains = res.data
                        this.isLoading = false
                    }
                }).catch(err => this.isLoading = true)
            }
        },
        async mounted() {
            this.isLoading = true
            this.remainInfo()
        }
    }
</script>

<style scoped lang="scss">
    .status-info {
        width: 100%;
        height: 100vh;
        background: hsl(210 11% 92% / 1);
        z-index: 9999;
        position: absolute;
        top: 52px;
    }
</style>